<template>
    <div class="Home">

      <page-header/>
      <!-- HERO -->
      <section>
        <div class="uk-container ">
          <div class="uk-text-center uk-margin">
            <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
              <li>
                <a href>Home</a>
              </li>
              <li>
                <span>Test Code Registration</span>
              </li>
            </ul>
            <!--<h1 class="uk-margin-small-top uk-margin-remove-bottom">Student register</h1>-->
          </div>

          <div class="uk-width-1-1 uk-width-expand@m uk-margin-medium-top">

            <div class="uk-margin">

              <div class="uk-text-emphasis uk-text-bold uk-grid-collapse" uk-grid>

                <div class="top-toggle uk-width-1-1 padding-vertical-small">
                  <ul class="uk-list uk-column-1-2@m uk-margin-remove">
                    <li>
                      <div class="uk-flex-middle uk-text-top uk-grid-small" uk-grid>
                        <label class="uk-form-label">Date:</label>
                        <div class="uk-grid-small uk-width-expand" uk-grid uk-margin>
                          <div class="uk-width-expand@s">
                            <input class="uk-input uk-form-small uk-width-1-1" type="text"/>
                          </div>
                          <div class="uk-visible@s">
                            <span>~</span>
                          </div>
                          <div class="uk-width-expand@s">
                            <input class="uk-input uk-form-small uk-width-1-1" type="text"/>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="uk-flex-middle uk-grid-small" uk-grid>

                        <label class="uk-form-label" for="form-select">Date:</label>
                        <div class="uk-width-expand">
                          <select id="form-select" class="uk-select uk-form-small uk-width-1-1">
                            <option>Hello World</option>
                            <option>Hello World</option>
                            <option>Hello World</option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="uk-flex-middle uk-grid-small" uk-grid>

                        <label class="uk-form-label">Exam Type:</label>

                        <div class="uk-form-controlls" uk-grid>
                          <label>
                            <input class="uk-radio" type="radio" name="radio"/> All
                          </label>
                          <label>
                            <input class="uk-radio" type="radio" name="radio"/> Normal
                          </label>
                          <label>
                            <input class="uk-radio" type="radio" name="radio"/> Exam Code
                          </label>
                        </div>

                      </div>
                    </li>
                    <li>
                      <div class="uk-flex-middle uk-grid-small" uk-grid>
                        <label class="uk-form-label">Subject:</label>

                        <div class="uk-width-expand">
                          <div class="uk-grid-small" uk-grid uk-margin>
                            <div class="uk-width-3-5@s">
                              <input class="uk-input uk-form-small" type="text"/>
                            </div>
                            <div class="uk-width-expand"></div>
                            <button
                              class="uk-button uk-button-primary uk-border-rounded uk-form-small uk-width-1-5@s"
                            >Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <hr class="uk-divider-icon">
                </div>
                <div class="uk-width-expand uk-flex uk-flex-right">
                  <button type="button" uk-toggle="target: .top-toggle" class="uk-button uk-button-small uk-border-square">
                    <span class="top-toggle">Refine search</span>
                    <span class="top-toggle">Search collapse</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="uk-card uk-card-default uk-card-small tm-ignore-container">

              <header class="uk-card-header">
                <div class="uk-grid-small uk-flex-middle" uk-grid>
                  <div
                    class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small"
                  >
                    <label>My Point : (P) 15,350 </label>
                    <!--<span-->
                    <!--style="margin-right:10px"-->
                    <!--class="uk-flex uk-flex-middle"-->
                    <!--&gt;Sort by:</span>-->

                    <!--<div uk-form-custom="target: > * > span:first-child">-->

                    <!--<select v-model="sortselected" @change="onChangeSort($event)">-->
                    <!--<option value="norm">Normal</option>-->
                    <!--<option value="name">Name</option>-->
                    <!--<option value="email">Email</option>-->
                    <!--<option value="enabled">Enabled</option>-->
                    <!--</select>-->
                    <!--<button-->
                    <!--class="uk-button uk-button-default"-->
                    <!---->
                    <!--tabindex="-1"-->
                    <!--&gt;-->
                    <!---->
                    <!--<span uk-icon="icon: chevron-down"></span>-->
                    <!--</button>-->
                    <!--</div>-->
                  </div>
                  <div
                    class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-center uk-flex-middle"
                  >

                    <router-link class="uk-button uk-button-default uk-button-small uk-hidden@m"

                                 title="Add exam" tag="a" :to="{name: 'organizexamadd',params: {
                                        examtype: 'free'}}"><span
                      class="uk-margin-xsmall-right"
                      uk-icon="icon: plus; ratio: .75;"
                    ></span> Exam code register
                    </router-link>

                    <div class="tm-change-view uk-margin-small-left">
                      <ul class="uk-subnav uk-iconnav js-change-view">
                        <li>
                          <router-link class="uk-button uk-button-default uk-button-small"
                                       style="color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"
                            title="Exam code register" tag="a" :to="{name: 'organizexamadd',params: {
                            examtype: 'free'}}"><span
                            class="uk-margin-xsmall-right"
                            uk-icon="icon: plus; ratio: .95;"
                          ></span> Exam code register
                          </router-link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </header>
              <div class="uk-card-body">
                <table v-if="exams!=null" id="example"
                       class="uk-table uk-table-hover  uk-table-divider uk-table-striped uk-table-responsive"
                       style="width:100%">
                  <thead>
                  <tr>

                    <th>Exam code</th>
                    <th>Exam name</th>
                    <th>Exam date</th>
                    <!--<th>Exam status</th>-->
                    <!--<th>Exam total score</th>-->
                    <th>Exam code registration date</th>
                    <th>Exam code expiry date</th>

                    <th>Time</th>
                    <th>Staring</th>
                    <th>Result</th>

                  </tr>
                  </thead>
                  <tbody v-if="exams.length > 0">
                  <template v-for="item in exams"
                            :item="item">
                    <!-- Tseekuu нэмж өгөв v-bind:key="item.id" -->
                    <tr v-bind:key="item.id">
                      <td>{{item.examcode}}</td>
                      <td>{{item.examround.exam.examname}} ({{item.examround.examformname}})</td>
                      <td>{{item.examround.examdate}}</td>
                      <!--<td>{{item.examround.examstatus}}</td>-->
                      <!--<td>{{item.examround.examtotalscore}}</td>-->
                      <td>{{item.payeddate|moment}}</td>
                      <td>{{item.expiredate|moment}}</td>
                      <td>{{item.expireday}} day</td>
                      <td><button type="button"  class="uk-button uk-button-default uk-button-small uk-border-rounded">Staring</button></td>
                      <td><button type="button"  class="uk-button uk-button-default uk-button-small uk-border-rounded">Result</button></td>

                    </tr>

                  </template>
                  </tbody>

                </table>
                <div v-else class="uk-alert-primary " uk-alert>
                  <p class="uk-text-center">Result 0</p>
                </div>
              </div>
              <div class="uk-card-footer uk-text-center">
                <div class="uk-flex uk-flex-center">
                  <v-pagination v-model="currentPage"
                                :page-count="pages"
                                :classes="uikitClasses"
                                :labels="Labels"
                                @change="onChange"></v-pagination>

                </div>

              </div>


            </div>
          </div>

        </div>


      </section>
      <page-footer/>
    </div>

</template>


<script scoped>
  import StudentService from '@/services/StudentService'
  import moment from 'moment'
  import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
	const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")
  import vPagination from '@/components/globals/vue-plain-pagination'

  export default {
    name: 'Home',
    components: {
      vPagination,
      PageHeader,
      PageFooter
    },
    data() {
      return {
        sdate: null,
        edate: null,
        name: null,
        error: null,
        sortselected: 'norm',
        exams: null,
        currentPage: 1,
        pages: 0,
        uikitClasses: {
          ul: 'uk-pagination',
          li: '',
          liActive: 'uk-active',
          liDisable: 'uk-disabled',
          button: 'page-link'
        },
        Labels: {
          first: 'First',
          prev: '<span uk-pagination-previous></span>',
          next: '<span uk-pagination-next></span>',
          last: "<span uk-icon='chevron-double-right'; ratio = '1.2'></span>"
        }


      }
    },

    filters: {
      moment: function (date) {
        // return moment(date).format('YYYY.MM.DD')
        return moment(date).format('YYYY-MM-DD, h:mm:ss')
      }
    },
    created() {
    },

    async mounted() {
      this.loaddata(this.currentPage)
    },
    methods: {
      // onChangeSort(event) {
      //   this.loaddata(this.currentPage)
      // },
      searchDate() {
        this.loaddata(this.currentPage)
      },


      // async examresult(round) {
      //   try {
      //     const response = await ExamService.getExamResults(round.id)
      //
      //     if (response.data) {
      //       console.log(response.data)
      //       this.results = response.data
      //       UIkit.modal('#modal-examresult', {modal: false, keyboard: false, bgclose: false, center: true}).show();
      //     }
      //
      //   } catch (error) {
      //     console.log(error)
      //
      //   }
      // },

      async loaddata(page) {
        try {

          if (page > 0) {
            page = page - 1
          }

          const response = await StudentService.getStudentExamCodes({
            page: page,
            sort: this.sortselected

          });

          console.log(response)

          if (response.status == 200) {
            this.exams = response.data.content;
            // this.currentPage = response.data.page;
            this.pages = response.data.totalPages;


            if (!this.exams.length) {
              this.isLoading = false
              return
            }
          }
        } catch (error) {
          console.log(error.response)
          this.error = error.response.data.message;
          this.scrollToTop()
        }

      },
      scrollToTop() {
        window.scrollTo(0, 0)
      }
      ,
      onChange: function () {
        // eslint-disable-next-line no-console
        console.log(`"currentPage" has been changed`)
        this.loaddata(this.currentPage)
      }

    }
    ,
    computed: {}

  }
</script>

<style scoped>
  .uk-pagination > li > a {
    font-size: 18px !important;
  }

  table tr td {
    border: 1px solid #ccc;
  }
  .uk-input{
    border-color: rgb(103, 56, 233);
  }
</style>
